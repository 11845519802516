<template>
  <div :style="`font-family:${font},sans-serif`">
    <div class="row justify-content-center justify-content-around">
      <div class="card col-12 col-md-12 p-1">
        <div>
          <div class="title head">
            <p class="h2" style="color: #558cef">รายการ Job</p>
          </div>
          <hr />
          <!-- search input -->
          <div class="custom-search row justify-content-between">
            <div class="col-12 col-md-3 mb-1" v-if="status_admin === 'แอดมิน'">
              <div>
                <b-button v-b-modal.modal-1 @click="create_job()">สร้าง JOB</b-button>
              </div>
            </div>

            <div class="col-12 col-md-3 mb-1">
              <b-button v-b-modal.modal-2>ยืนยันตรวจนับทรัพย์สิน</b-button>
            </div>
            <b-form-group class="col-12 col-md-6">
              <div class="d-flex align-items-center">
                <label class="mr-1">ค้นหา</label>
                <b-form-input v-model="searchTerm" placeholder="ค้นหา" type="text" class="d-inline-block" />
                <div>
                  <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                    <template v-slot:button-content>
                      <b-button variant="outline-primary"
                        ><feather-icon icon="ShareIcon" class="mr-50" />Export
                        <feather-icon icon="ChevronDownIcon" class="mr-50"
                      /></b-button>
                    </template>

                    <b-dropdown-item @click="exportExcel">
                      <feather-icon icon="FileIcon" class="mr-50" />
                      <span>Excel</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </b-form-group>
          </div>

          <!-- table -->
          <b-overlay :show="show" rounded="sm">
            <div v-if="status_admin === 'แอดมิน'">
              <vue-good-table
                :columns="columns"
                :rows="rows"
                :search-options="{
                  enabled: true,
                  externalQuery: searchTerm,
                }"
                :pagination-options="{
                  enabled: true,
                  perPage: pageLength,
                }"
                :line-numbers="true"
              >
                >
                <template slot="table-row" slot-scope="props">
                  <!-- Column: Name -->
                  <span v-if="props.column.field === 'fullName'" class="text-nowrap">
                    <b-avatar :src="props.row.avatar" class="mx-1" />
                    <span class="text-nowrap">{{ props.row.fullName }}</span>
                  </span>

                  <!-- Column: Status -->
                  <span v-else-if="props.column.field === 'status'">
                    <b-badge :variant="statusVariant(props.row.status)">
                      {{ props.row.status }}
                    </b-badge>
                  </span>

                  <!-- Column: Action -->
                  <span v-else-if="props.column.field === 'insert'">
                    <span>
                      <b-button variant="outline-info" :to="{ name: 'incounting', params: { id: props.row } }">
                        <feather-icon icon="EyeIcon" size="16" class="mx-1" />
                      </b-button>
                    </span>
                  </span>

                  <!-- Column: Action -->

                  <span v-else-if="props.column.field === 'action'">
                    <span>
                      <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                        <template v-slot:button-content>
                          <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
                        </template>
                        <b-dropdown-item v-b-modal.modal-1 @click="edit_data(props.row)">
                          <feather-icon icon="Edit2Icon" class="mr-50" />
                          <span>แก้ไข</span>
                        </b-dropdown-item>

                        <b-dropdown-item @click="delete_data(props.row)">
                          <feather-icon icon="TrashIcon" class="mr-50" />
                          <span>ลบ</span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </span>
                  </span>
                  <!-- Column: Common -->
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>

                <!-- pagination -->
                <template slot="pagination-bottom" slot-scope="props">
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap"> Showing 1 to </span>
                      <b-form-select
                        v-model="pageLength"
                        :options="['5', '10', '15', '25', '50', '100']"
                        class="mx-1"
                        @input="(value) => props.perPageChanged({ currentPerPage: value }, handleChangePage(value))"
                      />
                      <span class="text-nowrap">of {{ props.total }} entries</span>
                    </div>
                    <div>
                      <b-pagination
                        :value="1"
                        :total-rows="props.total"
                        :per-page="pageLength"
                        first-number
                        last-number
                        align="right"
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mt-1 mb-0"
                        @input="handlePagechange($event)"
                      >
                        <template #prev-text>
                          <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                          <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                      </b-pagination>
                    </div>
                  </div>
                </template>
              </vue-good-table>
            </div>
            <div v-else>
              <vue-good-table
                :columns="columns"
                :rows="rows"
                :search-options="{
                  enabled: true,
                  externalQuery: searchTerm,
                }"
                :pagination-options="{
                  enabled: true,
                  perPage: pageLength,
                }"
                :line-numbers="true"
              >
                >
                <template slot="table-row" slot-scope="props">
                  <!-- Column: Name -->
                  <span v-if="props.column.field === 'fullName'" class="text-nowrap">
                    <b-avatar :src="props.row.avatar" class="mx-1" />
                    <span class="text-nowrap">{{ props.row.fullName }}</span>
                  </span>

                  <!-- Column: Status -->
                  <span v-else-if="props.column.field === 'status'">
                    <b-badge :variant="statusVariant(props.row.status)">
                      {{ props.row.status }}
                    </b-badge>
                  </span>

                  <!-- Column: Action -->
                  <span v-else-if="props.column.field === 'insert'">
                    <span>
                      <b-button variant="outline-info" :to="{ name: 'incounting', params: { id: props.row } }">
                        <feather-icon icon="EyeIcon" size="16" class="mx-1" />
                      </b-button>
                    </span>
                  </span>

                  <!-- Column: Action -->

                  <!-- <span v-else-if="props.column.field === 'action'">
                  <span>
                    <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                      <template v-slot:button-content>
                        <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
                      </template>
                      <b-dropdown-item v-b-modal.modal-1 @click="edit_data(props.row)">
                        <feather-icon icon="Edit2Icon" class="mr-50" />
                        <span>แก้ไข</span>
                      </b-dropdown-item>

                      <b-dropdown-item @click="delete_data(props.row)">
                        <feather-icon icon="TrashIcon" class="mr-50" />
                        <span>ลบ</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </span>
                </span> -->
                  <!-- Column: Common -->
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>

                <!-- pagination -->
                <template slot="pagination-bottom" slot-scope="props">
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap"> Showing 1 to </span>
                      <b-form-select
                        v-model="pageLength"
                        :options="['5', '10', '15', '25', '50', '100']"
                        class="mx-1"
                        @input="(value) => props.perPageChanged({ currentPerPage: value }, handleChangePage(value))"
                      />
                      <span class="text-nowrap">of {{ props.total }} entries</span>
                    </div>
                    <div>
                      <b-pagination
                        :value="1"
                        :total-rows="props.total"
                        :per-page="pageLength"
                        first-number
                        last-number
                        align="right"
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mt-1 mb-0"
                        @input="handlePagechange($event)"
                      >
                        <template #prev-text>
                          <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                          <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                      </b-pagination>
                    </div>
                  </div>
                </template>
              </vue-good-table>
            </div>
          </b-overlay>
        </div>
      </div>
      <!-- <div class="card col-12 col-md-2"></div> -->
    </div>

    <!-- //model -->
    <div>
      <b-modal
        id="modal-1"
        cancel-variant="outline-secondary"
        ok-title="บันทึก"
        cancel-title="ยกเลิก"
        :title="`${edit_data_name == 'แก้ไข' ? 'แก้ไข' : ''}ตรวจนับทรัพย์สิน`"
        size="lg"
        @ok="post_data"
        no-close-on-backdrop
      >
        <b-form>
          <div>
            <b-form-group label-cols="4" label-cols-lg="2" label="กลุ่มงาน:">
              <template v-slot:label> Job <span class="text-danger"> *</span> </template>
              <b-form-input placeholder="สร้างชื่อ Job " v-model="work_gid" label="work_gname" :options="workGs" />
            </b-form-group>
            <div v-if="edit_data_name == 'แก้ไข'">
              <b-form-group label-cols="4" label-cols-lg="2" label="ผู้ตรวจนับ:">
                <template v-slot:label> ผู้ตรวจนับ </template>
                <v-select
                  label="firstname"
                  :options="alluser"
                  id="basicInput"
                  v-model="data.requester_name_counter"
                  placeholder="ผู้ตรวจนับ"
                  @input="as_position(data.requester_name_counter)"
                />
              </b-form-group>
              <b-form-group label-cols="4" label-cols-lg="2" label="ตำเเหน่ง:">
                <template v-slot:label> ตำเเหน่ง </template>
                <v-select
                  :options="department_Lists"
                  label="dep_id"
                  v-model="data.requester_position_counter_department"
                  placeholder="ตำเเหน่ง"
                  disabled
                />
              </b-form-group>
            </div>
            <div v-else>
              <b-form-group label-cols="4" label-cols-lg="2" label="ผู้ตรวจนับ:">
                <template v-slot:label> ผู้ตรวจนับ <span class="text-danger"> *</span> </template>
                <v-select
                  label="firstname"
                  :options="alluser"
                  id="basicInput"
                  v-model="data.requester_name_counter"
                  placeholder="ผู้ตรวจนับ"
                  @input="as_position(data.requester_name_counter)"
                />
              </b-form-group>
              <b-form-group label-cols="4" label-cols-lg="2" label="ตำเเหน่ง:">
                <template v-slot:label> ตำเเหน่ง <span class="text-danger"> *</span> </template>
                <v-select
                  :options="department_Lists"
                  label="dep_id"
                  v-model="data.requester_position_counter_department"
                  placeholder="ตำเเหน่ง"
                  disabled
                />
              </b-form-group>
            </div>

            <div class="" v-for="(list_departments, index) in list_department" :key="index">
              <div>
                <!-- {{ list_departments }} -->
                ผู้ตรวจนับ: {{ list_departments[0] }} ตำเเหน่ง:
                {{ list_departments[1] }}
                <b-button size="sm" variant="danger" @click="list_department.splice(index, 1)">ลบ</b-button>
              </div>
            </div>
            <b-form-group class="mt-1" label-cols="4" label-cols-lg="2" label="รหัส:">
              <template v-slot:label> รหัส <span class="text-danger"> *</span> </template>
              <b-form-input id="basicInput" placeholder="AUTO" v-model="data.code" disabled />
            </b-form-group>
            <b-form-group label-cols="4" label-cols-lg="2" label="รายละเอียด:">
              <template v-slot:label> รายละเอียด <span class="text-danger"> *</span> </template>
              <b-form-input id="basicInput" v-model="data.information" placeholder="รายละเอียด" />
            </b-form-group>
            <b-form-group label-cols="4" label-cols-lg="2" label="วันที่เริ่มต้น:">
              <template v-slot:label> วันที่เริ่มต้น <span class="text-danger"> *</span> </template>
              <!-- <b-form-datepicker
                placeholder="วันที่เริ่มต้น"
                v-model="data.start_date"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
                locale="th"
              /> -->
              <v-date-picker
                id="example-datepicker3"
                label="วันที่เริ่มต้น:"
                locale="th-TH"
                v-model="date1"
                @update:from-page="getHeaderInputDate"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <b-form-input :value="formatDate(inputValue, 'date1')" v-on="inputEvents" />
                </template>
                <template #header-title>
                  {{ headerInputDate }}
                </template>
              </v-date-picker>
            </b-form-group>
            <b-form-group label-cols="4" label-cols-lg="2" label="ถึงวันที่:">
              <template v-slot:label> ถึงวันที่ <span class="text-danger"> *</span> </template>
              <v-date-picker
                id="example-datepicker3"
                label="ถึงวันที่:"
                locale="th-TH"
                v-model="date2"
                @update:from-page="getHeaderInputDate"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <b-form-input :value="formatDate(inputValue, 'date2')" v-on="inputEvents" />
                </template>
                <template #header-title>
                  {{ headerInputDate }}
                </template>
              </v-date-picker>
              <!-- <b-form-datepicker
                placeholder="ถึงวันที่"
                v-model="data.stop_date"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
                locale="th"
              /> -->
            </b-form-group>
            <b-form-group label-cols="4" label-cols-lg="2" label="ปีงบประมาณ:">
              <template v-slot:label> ปีงบประมาณ <span class="text-danger"> *</span> </template>
              <b-form-input
                id="basicInput"
                v-model="data.budget_year"
                placeholder="ปีงบประมาณ"
                maxlength="4"
                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
              />
            </b-form-group>
            <b-form-group label-cols="4" label-cols-lg="2" label="ผู้ขออนุญาต:">
              <template v-slot:label> ผู้ขออนุญาต <span class="text-danger"> *</span> </template>
              <v-select
                label="firstname"
                :options="alluser"
                id="basicInput"
                v-model="data.requester_name"
                placeholder="ผู้ขออนุญาต"
                @input="a_position(data.requester_name)"
              />
            </b-form-group>
            <b-form-group label-cols="4" label-cols-lg="2" label="ตำเเหน่ง:">
              <template v-slot:label> ตำเเหน่ง <span class="text-danger"> *</span> </template>
              <v-select
                :options="department_Lists"
                label="dep_id"
                v-model="data.requester_position"
                placeholder="ตำเเหน่ง"
                disabled
              />
            </b-form-group>
            <b-form-group label-cols="4" label-cols-lg="2" label="ผู้อนุมัติ:">
              <template v-slot:label> ผู้อนุมัติ <span class="text-danger"> *</span> </template>
              <v-select
                label="firstname"
                :options="alluser"
                id="basicInput"
                v-model="data.approver_name"
                placeholder="ผู้อนุมัติ"
                @input="b_position(data.approver_name)"
              />
            </b-form-group>
            <b-form-group label-cols="4" label-cols-lg="2" label="ตำเเหน่ง:">
              <template v-slot:label> ตำเเหน่ง <span class="text-danger"> *</span> </template>
              <v-select
                :options="department_Lists"
                label="dep_id"
                v-model="data.approver_position"
                placeholder="ตำเเหน่ง"
                disabled
              />
            </b-form-group>
            <hr />
            <b-form-group label-cols="4" label-cols-lg="2" label="กรรมการตรวจนับ 1">
              <v-select
                label="firstname"
                :options="alluser"
                id="basicInput"
                v-model="data.director_one"
                placeholder="ชื่อ-สกุล"
              />
            </b-form-group>
            <b-form-group label-cols="4" label-cols-lg="2" label="กรรมการตรวจนับ 2">
              <v-select
                label="firstname"
                :options="alluser"
                id="basicInput"
                v-model="data.director_two"
                placeholder="ชื่อ-สกุล"
              />
            </b-form-group>

            <b-form-group label-cols="4" label-cols-lg="2" label="กรรมการตรวจนับ 3">
              <v-select
                label="firstname"
                :options="alluser"
                id="basicInput"
                v-model="data.director_three"
                placeholder="ชื่อ-สกุล"
              />
            </b-form-group>

            <!-- <b-form-select v-model="selected" :options="options" /> -->
          </div>
        </b-form>
      </b-modal>
      <b-modal
        id="modal-2"
        title="ยืนยันตรวจนับทรัพย์สิน"
        ok-title="ยืนยัน"
        cancel-title="ยกเลิก"
        @ok="confirmation"
        no-close-on-backdrop
      >
        <b-card-text>
          <b-form-group label="ยืนยันตรวจนับทรัพย์สิน" label-for="basicInput">
            <b-form-input id="basicInput" v-model="check_status" placeholder="รหัส ตัวอย่าง 2565-001" />
          </b-form-group>
        </b-card-text>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { BTabs, BTab } from "bootstrap-vue";
import { BFormInput, BRow, BCol, BFormGroup } from "bootstrap-vue";
import { BTable, BFormRadio, BFormCheckbox, BFormRadioGroup, BCardBody } from "bootstrap-vue";
import vSelect from "vue-select";
import { BButton } from "bootstrap-vue";
import { BFormFile } from "bootstrap-vue";
import { BFormSelect } from "bootstrap-vue";
import { BFormDatepicker } from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import axios from "axios";
import API from "@/views/connectDB/condb.js";
import { saveExcel } from "@progress/kendo-vue-excel-export";
import { DatePicker } from "v-calendar";

export default {
  components: {
    VueGoodTable,
    BFormDatepicker,
    BFormSelect,
    BFormFile,
    BRow,
    BCol,
    BButton,
    BTable,
    BCardBody,
    vSelect,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BTabs,
    BTab,
    DatePicker,
  },

  data() {
    return {
      department_Lists: [],
      headerInputDate: "",
      date1: new Date(),
      date2: new Date(),

      alluser: [],
      check_status: "",
      show: false,
      data: {
        code: "",
        information: "",
        start_date: "",
        stop_date: "",
        budget_year: "",
        requester_name: "",
        requester_name_counter: "",
        requester_position_counter: "",
        requester_position_counter_department: "",
        requester_position: "",
        approver_name: "",
        approver_position: "",
        director_one: "",
        director_two: "",
        director_three: "",
      },
      pageLength: 50,
      currentPage: 1,
      total: "",
      dir: false,
      work_gid: "",
      workGs: [],
      status_admin: "",
      fullname: "",
      columns: [
        {
          label: "เริ่มวันที่",
          field: "start_date_th",
        },
        {
          label: "ถึงวันที่",
          field: "stop_date_th",
        },
        {
          label: "ปีงบประมาณ",
          field: "budget_year",
        },
        {
            label: 'รหัส',
            field: 'check_code',
          },
          {
            label: 'เลขทะเบียน',
            field: 'equipment_code',
          },
        {
          label: "รายละเอียด",
          field: "information",
        },

        {
          label: "สถานะ",
          field: "check_status",
        },

        {
          label: "ตรวจนับ",
          field: "insert",
        },
        {
          label: "ลบ/แก้ไข",
          field: "action",
        },
      ],
      visibleColumns: ["insert", "action"],
      rows: [],
      searchTerm: "",
      month: [
        "",
        "มกราคม",
        "กุมภาพันธ์ ",
        "มีนาคม ",
        "เมษายน ",
        "พฤษภาคม ",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
      ],
      list_department: [],
      edit_data_name: "",
      edit_id: "",
    };
  },
  async mounted() {
    const font = localStorage.getItem("font");
    console.log(font);
    this.font = font;
    const { usertype, fullname } = await JSON.parse(localStorage.getItem("username_type"));
    console.log(usertype, fullname);
    this.status_admin = usertype;
    this.fullname = fullname;
    this.get_data_table();
    this.genCounting();
    this.get_work();
    this.get_data_alluser();
    this.departmentLists();
  },
  methods: {
    delete_data(value) {
      console.log(value);
      Swal.fire({
        title: "คุณต้องการจะลบข้อมูลใช่หรือไม่",
        text: `JOB ${value.job_code}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ตกลง",
        cancelButtonText: "ยกเลิก",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },

        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          const { access_token } = await this.access_token();
          const url = `${API}jobList/${value.id}`;
          const head = {
            headers: {
              Authorization: await access_token,
            },
          };

          const res = await axios.delete(url, head);

          Swal.fire({
            icon: "success",
            title: "ลบข้อมูลสำเร็จ!",
            text: `รายการนี้ ถูกลบเเล้ว ${value.job_code}`,
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          this.get_data_table();
        }
      });
    },
    create_job() {
      this.edit_data_name = null;
      this.genCounting();
      this.list_department = [];
      this.data.information = "";
      this.data.start_date = "";
      this.data.stop_date = "";
      this.data.budget_year = "";
      this.data.requester_name = "";
      this.data.requester_name_counter = "";
      this.data.requester_position_counter = "";
      this.data.requester_position_counter_department = "";
      this.data.requester_position = "";
      this.data.approver_name = "";
      this.data.approver_position = "";
      this.data.director_one = "";
      this.data.director_two = "";
      this.data.director_three = "";
    },
    edit_data(value) {
      this.edit_data_name = "แก้ไข";
      console.log(value);
      this.list_department = [];
      // this.data.requester_name_counter = value.approver_name;
      //   this.data.requester_position_counter_department = value.approver_position;
      this.edit_id = value.id;
      this.work_gid = value.code;
      this.data.code = value.job_code;
      this.data.information = value.information;
      this.data.start_date = value.start_date;
      this.data.stop_date = value.stop_date;
      this.data.budget_year = value.budget_year;
      this.data.requester_name = value.requester_name;
      this.data.requester_position = value.requester_position;
      this.data.approver_name = value.approver_name;
      this.data.approver_position = value.approver_position;
      this.data.director_one = value.director_one;
      this.data.director_two = value.director_two;
      this.data.director_three = value.director_three;
      const myString = value.checker_name;
      const myArray = myString.split(",").map((element) => element.replace(/'/g, ""));
      const myString1 = value.checker_position;
      const myArray1 = myString1.split(",").map((element) => element.replace(/'/g, ""));
      console.log(myArray);
      console.log(myArray1);
      myArray.map((el, index) => {
        console.log(el);
        this.list_department.push([myArray[index], myArray1[index]]);
      });
      console.log(this.list_department);
      // this.list_department.push(myArray,
      //   myArray1)
    },
    async as_position(value) {
      const { access_token } = await this.access_token();
      const url = `${API}registerWithdraw?fullname=${value.firstname}`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      // res.data.message.data[0].dep_id
      this.data.requester_position_counter_department = res.data.message.data[0].dep_id;
      const name = value.firstname;
      const department = this.data.requester_position_counter_department;
      console.log(name);
      console.log(department);

      this.list_department.push([name, department]);
      console.log(this.list_department);
      const a = [];
      const b = [];
      this.list_department.forEach((element) => {
        console.log(element);
        a.push(element[0]);
        b.push(element[1]);
      });
      console.log(a.toString());
      console.log(b.toString());
      // console.log(res.data.message.data);
    },
    async b_position(value) {
      // console.log(value.firstname);
      const { access_token } = await this.access_token();
      const url = `${API}registerWithdraw?fullname=${value.firstname}`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      // console.log(res.data.message.data);
      this.data.approver_position = res.data.message.data[0].dep_id;
    },

    async a_position(value) {
      // console.log(value.firstname);
      const { access_token } = await this.access_token();
      const url = `${API}registerWithdraw?fullname=${value.firstname}`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      // res.data.message.data[0].dep_id
      this.data.requester_position = res.data.message.data[0].dep_id;
      // console.log(res.data.message.data);
    },
    async departmentLists() {
      const { access_token } = await this.access_token();
      const url = `${API}departmentLists`;
      const head = {
        headers: {
          authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      this.department_Lists = res.data.message.data;
      // console.log(res.data.message.data);
    },
    async get_data_alluser() {
      this.show = true;
      const { access_token } = await this.access_token();
      const url = `${API}user?_page=1&_limit=100`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      const res_user = res.data.message.data.map((rs) => {
        return {
          ...rs,
          firstname: `${rs.title}${rs.firstname} ${rs.lastname}`,
        };
      });
      this.alluser = res_user;
      // console.log(res.data.message);
    },
    async confirmation() {
      const { access_token } = await this.access_token();
      const url = `${API}updateCountingStatus/${this.check_status}`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const data = {
        check_status: "เสร็จสิ้น",
      };
      const res = await axios.put(url, data, head);

      if (res.data.status === 200) {
        Swal.fire({
          position: "top-center",
          icon: "success",
          title: "ยืนยันตรวจนับทรัพย์สิน",
          showConfirmButton: false,
          timer: 1500,
        });
        this.get_data_table();
      } else {
        Swal.fire({
          position: "top-center",
          icon: "error",
          title: res.data.message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    exportExcel() {
      saveExcel({
        data: this.rows,
        fileName: "ตรวจนับทรัพย์สิน",
        columns: [
          {
            title: "ตรวจนับทรัพย์สิน",
            headerCellOptions: { textAlign: "center" },
            children: [
              {
                title: "เริ่มวันที่",
                field: "start_date_th",
              },
              {
                title: "ถึงวันที่",
                field: "stop_date_th",
              },
              {
                title: "ปีงบประมาณ",
                field: "budget_year",
              },
              {
                title: "รหัส",
                field: "check_code",
              },
              {
                title: "กลุ่มงาน",
                field: "work_gname",
              },
              {
                title: "รายละเอียด",
                field: "information",
              },

              {
                title: "สถานะ",
                field: "check_status",
              },
            ],
          },
        ],
      });
    },
    async get_work() {
      const { access_token } = await this.access_token();
      const url = `${API}workgroups`;
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, header);
      // console.log(res.data.message.data);
      const res_amp = res.data.message.data.map((element) => {
        return {
          ...element,
          work_gname: `${element.work_gid} - ${element.work_gname}`,
        };
      });
      this.workGs = res_amp;
    },
    async post_data() {
      console.log(this.list_department);
      const { access_token } = await this.access_token();

      if (this.edit_data_name == "แก้ไข") {
        console.log(this.edit_id);
        let a = [];
        let b = [];
        this.list_department.forEach((element) => {
          console.log(element);
          a.push(element[0]);
          b.push(element[1]);
        });
        const myString1 = a.map((element) => `'${element}'`).join(",");
        const myString2 = b.map((element) => `'${element}'`).join(",");
        console.log(myString1);
        console.log(myString2);
        const data = {
          job_code: this.data.code,
          code: this.work_gid,

          checker_name: myString1,
          checker_position: myString2,
          // check_code: this.data.budget_year + "-" + this.data.code,

          information: this.data.information,
          start_date: this.data.start_date,
          stop_date: this.data.stop_date,
          budget_year: this.data.budget_year,
          requester_name: this.data.requester_name,
          requester_position: this.data.requester_position.dep_id || this.data.requester_position,
          approver_name: this.data.approver_name,
          approver_position: this.data.approver_position.dep_id || this.data.approver_position,
          director_one: this.data.director_one,
          director_two: this.data.director_two,
          director_three: this.data.director_three,
          check_status: "กำลังตรวจนับ",
        };
        console.log(data);

        const url = `${API}jobList/${this.edit_id}`;
        const head = {
          headers: {
            Authorization: await access_token,
          },
        };
        const res = await axios.put(url, data, head);
        console.log(res);
        if (res.data.status === 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "แก้ไขสำเร็จ",
            showConfirmButton: false,
            timer: 1500,
          });
          this.get_data_table();
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: `${res.data.message}`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else {
        console.log(this.list_department);
        let a = [];
        let b = [];
        this.list_department.forEach((element) => {
          console.log(element);
          a.push(element[0]);
          b.push(element[1]);
        });
        console.log(a);
        console.log(b);
        const myString1 = a.map((element) => `'${element}'`).join(",");
        const myString2 = b.map((element) => `'${element}'`).join(",");
        console.log(myString1);
        console.log(myString2);
        const data = {
          job_code: this.data.code,
          code: this.work_gid,

          checker_name: myString1,
          checker_position: myString2,
          // check_code: this.data.budget_year + "-" + this.data.code,

          information: this.data.information,
          start_date: this.data.start_date,
          stop_date: this.data.stop_date,
          budget_year: this.data.budget_year,
          requester_name: this.data.requester_name.firstname,
          requester_position: this.data.requester_position.dep_id || this.data.requester_position,
          approver_name: this.data.approver_name.firstname,
          approver_position: this.data.approver_position.dep_id || this.data.approver_position,
          director_one: this.data.director_one.firstname,
          director_two: this.data.director_two.firstname,
          director_three: this.data.director_three.firstname,
          check_status: "กำลังตรวจนับ",
        };
        console.log(data);

        const url = `${API}jobList`;

        const head = {
          headers: {
            Authorization: await access_token,
          },
        };
        const res = await axios.post(url, data, head);
        // console.log(data);
        console.log(res.data);
        if (res.data.status === 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "บันทึก",
            showConfirmButton: false,
            timer: 1500,
          });
          this.get_data_table();
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: `${res.data.message}`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }

      console.log(data);
      return;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.post(url, data, head);
      // console.log(data);
      console.log(res.data);
      if (res.data.status === 200) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "บันทึก",
          showConfirmButton: false,
          timer: 1500,
        });
        this.get_data_table();
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: `${res.data.message}`,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    async genCounting() {
      const { access_token } = await this.access_token();
      const url = `${API}maxCodeJobs`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      console.log(res.data.message.data.job_code);
      this.data.code = `${res.data.message.data.job_code.toString().padStart(3, "0")}`;
    },
    handlePagechange(event) {
      this.currentPage = event;
      this.get_data_table();
    },
    handleChangePage(event) {
      this.pageLength = event;
      this.get_data_table();
    },
    async get_data_table() {
      this.show = true;
      const { access_token } = await this.access_token();
      const url = `${API}counting?_page=${this.currentPage}&_limit=${this.pageLength}&_sort=1`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      console.log(res.data.message);
      if (this.status_admin == "แอดมิน") {
        this.rows = res.data.message.data.map((element, index) => {
          console.log(element);
          return {
            ...element,
            index: `${index}`,
            start_date_th: ` ${parseInt(element.start_date.split("-")[2])} ${
              this.month[parseInt(element.start_date.split("-")[1])]
            } ${Number(element.start_date.split("-")[0]) + 543}`,
            stop_date_th: ` ${parseInt(element.stop_date.split("-")[2])} ${
              this.month[parseInt(element.stop_date.split("-")[1])]
            } ${Number(element.stop_date.split("-")[0]) + 543}`,
          };
        });
        console.log(this.rows);
        this.total = res.data.message.total;
      } else {
        console.log(this.fullname);
        const result = [];
        result.push(res.data.message.data.find((subArray) => subArray.checker_name.includes(this.fullname)));

        // console.log(result)
        // console.log(res.data.message.data)
        this.rows = result.map((element, index) => {
          return {
            ...element,
            checker_name: element.checker_name ? element.checker_name.replace(/'/g, "").split(",") : "",
            index: `${index}`,
            start_date_th: ` ${parseInt(element.start_date.split("-")[2])} ${
              this.month[parseInt(element.start_date.split("-")[1])]
            } ${Number(element.start_date.split("-")[0]) + 543}`,
            stop_date_th: ` ${parseInt(element.stop_date.split("-")[2])} ${
              this.month[parseInt(element.stop_date.split("-")[1])]
            } ${Number(element.stop_date.split("-")[0]) + 543}`,
          };
        });
      }
      this.show = false;
    },
    async access_token() {
      return (
        await axios.post(`${API}permit`, "", {
          headers: {
            Authorization: localStorage.getItem("storedData") && JSON.parse(localStorage.getItem("storedData")),
          },
        })
      ).data.message;
    },
    getHeaderInputDate(events) {
      switch (events.month) {
        case 1:
          this.headerInputDate = "มกราคม " + (events.year + 543);
          break;
        case 2:
          this.headerInputDate = "กุมภาพันธ์ " + (events.year + 543);
          break;
        case 3:
          this.headerInputDate = "มีนาคม " + (events.year + 543);
          break;
        case 4:
          this.headerInputDate = "เมษายน " + (events.year + 543);
          break;
        case 5:
          this.headerInputDate = "พฤษภาคม " + (events.year + 543);
          break;
        case 6:
          this.headerInputDate = "มิถุนายน " + (events.year + 543);
          break;
        case 7:
          this.headerInputDate = "กรกฎาคม " + (events.year + 543);
          break;
        case 8:
          this.headerInputDate = "สิงหาคม " + (events.year + 543);
          break;
        case 9:
          this.headerInputDate = "กันยายน " + (events.year + 543);
          break;
        case 10:
          this.headerInputDate = "ตุลาคม " + (events.year + 543);
          break;
        case 11:
          this.headerInputDate = "พฤศจิกายน " + (events.year + 543);
          break;
        case 12:
          this.headerInputDate = "ธันวาคม " + (events.year + 543);
          break;
        default:
          this.headerInputDate = "ข้อมูลผิดพลาด";
          break;
      }
      // console.log(this.headerInputDate);
    },
    formatDate(date, val) {
      // console.log(val);
      let formatDate = "";
      let dete_val = "";
      if (date != null) {
        const _y = date.split("/");
        const _date = parseInt(_y[2]) + 543;
        formatDate = `${_y[0]}/${_y[1]}/${_date}`;
        dete_val = _y[2] + "-" + _y[1] + "-" + _y[0];
        switch (val) {
          case "date1":
            this.data.start_date = dete_val;
            break;
          case "date2":
            this.data.stop_date = dete_val;
            break;
        }
      } else {
        formatDate = "-";
      }
      if (formatDate.toString() == "/undefined/NaN") {
        formatDate = "";
      }
      return formatDate;
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
